import axios from "axios";
import { IPublicClientApplication } from "@azure/msal-browser";
import { ReportObject } from "../models/ReportModel";

export async function getUserAuthToken(msalInstance:IPublicClientApplication) {
	const backendClientScope = "api://" + process.env.REACT_APP_BACKEND_APP_ID + "/saturn_user_impersonation"
	var tokenRequest = {
		account: msalInstance.getAllAccounts()[0],
		scopes: [backendClientScope]
	};
	let token_call = await msalInstance.acquireTokenSilent(tokenRequest)
	let token = "Bearer " + token_call.accessToken
	return token
}

async function getUserAuthEntries(msalInstance:IPublicClientApplication) {
	const backendClientScope = "api://" + process.env.REACT_APP_BACKEND_APP_ID + "/saturn_user_impersonation"
	var tokenRequest = {
		account: msalInstance.getAllAccounts()[0],
		scopes: [backendClientScope]
	};
	let token_call = await msalInstance.acquireTokenSilent(tokenRequest)
	const result = {
		environment: token_call.account?.environment,
		nonce: token_call.account?.idTokenClaims?.nonce,
		sub: token_call.account?.idTokenClaims?.sub
	}

	return result
}

function getRefreshTokenFromStorage(environment:string) {
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i)

		if (key?.includes(`-${environment}-refreshtoken-`)) {
			const storageItem = localStorage.getItem(key)
			let refreshTokenObj
			if (storageItem !== null) {
				refreshTokenObj = JSON.parse(storageItem)
			}

			if (refreshTokenObj && refreshTokenObj.secret) {
				return refreshTokenObj.secret
			}
		}
	}
	return null
}

/**
 * 
 * @param {*} msalInstance instance of msal PCA 
 * @returns api response containing user details
 */
export async function getUserDetails(msalInstance:IPublicClientApplication) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/UserAuthentication"
	let res = await axios.get(baseUrl,{
		headers: { Authorization: token }
	})
	
	return res
}

/**
 * 
 * @param {*} msalInstance instance of msal PCA 
 * @returns api response containing invalid reports
 */
export async function getDbReports(msalInstance:IPublicClientApplication, valid:boolean) {
	let token = await getUserAuthToken(msalInstance)
    
    const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/ReportDashboard"
    let dbReportsResponse = await axios.get(`${baseUrl}?valid=${valid}`,{
			headers: { Authorization: token }
		})

    return dbReportsResponse 
  }

export async function getReportsByType(msalInstance:IPublicClientApplication, valid:boolean, customerId:string, reportType:string, offset:number) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/ReportDashboard"

	let dbReportsResponse = await axios.get(
		`${baseUrl}?valid=${valid}&customerId=${customerId}&reportType=${reportType}&offset=${offset}`,
		{
			headers: { Authorization: token }
		})

	return dbReportsResponse 
}

export async function getPublishedReport(msalInstance:IPublicClientApplication, customerId:string, reportId:string) {
	let token = await getUserAuthToken(msalInstance)

    const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/ReportDashboard"

    let dbReportsResponse = await axios.get(
		`${baseUrl}?customerId=${customerId}&reportId=${reportId}`,
		{
			headers: { Authorization: token }
		})

    return dbReportsResponse 
  }

export async function generateReportFile(msalInstance:IPublicClientApplication, queryObj:{[key: string]: any} = {}) {
	let token = await getUserAuthToken(msalInstance)

	const authEntries = await getUserAuthEntries(msalInstance)
	Object.entries(authEntries).forEach(([key, value]) => queryObj[key] = value)
	
	let rt
	if (authEntries.environment) {
		rt = getRefreshTokenFromStorage(authEntries.environment)
		queryObj["rt"] = rt
	}		

    const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/ReportDashboard"
	queryObj["dataType"] = "reportFile"
    let dbReportsResponse = await axios.post(
		baseUrl,
		JSON.stringify(queryObj),
		{
			headers: { 
				Authorization: token,
			},
			responseType: "arraybuffer"
		})

    return dbReportsResponse 
}

/**
 * 
 * @param {*} msalInstance instance of msal PCA
 * @param {*} report report to be put in db in JSON string
 * @returns api response
 */
export async function putReportToDb(msalInstance:IPublicClientApplication, report:ReportObject, updateType:string = "save") {
	let token = await getUserAuthToken(msalInstance)

	const queryObj = {
		updateType: updateType,
		report: report
	}
	
	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/ReportDashboard"
	let dbResponse = await axios.put(
			baseUrl,
			JSON.stringify(queryObj),
			{ headers: { Authorization: token }}
		)

	return dbResponse
}

export async function reGenerateReport(msalInstance:IPublicClientApplication, customerId: string, reportType:string, reportId:string) {
	let token = await getUserAuthToken(msalInstance)

	let reportProduct = "sherlock"
	if (!reportType.includes("sherlock")) {
		reportProduct = reportType.split("_").slice(0,2).join("_")
	}	

	const reportFrequency = reportType.split("_").slice(-1)[0]
	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/ReportDashboard"
	const reqObj = {
		"dataType": "reportRegeneration",
		"customerId": customerId,
		"reportProduct": reportProduct,
		"reportFrequency": reportFrequency,
		"reportId": reportId
	}

	let response = await axios.post(
		baseUrl,
		reqObj,
		{ headers: { Authorization: token }}
	)

	return response		
}
/**
 * 
 * @param {*} msalInstance instance of msal PCA 
 * @returns api response containing BAU accepted baselines
 */
export async function getBauBaselines(msalInstance:IPublicClientApplication, type:string, baselineId:string = "", cid:string = "", workspace:string = "") {
	let token = await getUserAuthToken(msalInstance)
	
	let baseUrl = process.env.REACT_APP_BACKEND_URL!+`/api/GetSentinelBaseline?type=${type}`
	if (baselineId !== "") {
		baseUrl += `&baseline_id=${baselineId}`
	}
	if (type === "historical_ids" || type === "latest_hist_baseline") {
		baseUrl += `&cid=${cid}&workspace=${workspace}`
	}
	let dbBaselinesResponse = await axios.get(baseUrl,{
		headers: { Authorization: token }
		})

	return dbBaselinesResponse 
}

export async function getPagedSherlockData(msalInstance:IPublicClientApplication, type:string , ts:number=0, id:string="") {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/SherlockDashboard"
	let dbResponse = await axios.get(
		`${baseUrl}?type=${type}&ts=${ts}&id=${id}`,
		{ headers: { Authorization: token }}
	)

	return dbResponse	
}

/**
 * 
 * @param {*} msalInstance instance of msal PCA 
 * @returns api response containing Sentinel backup files
 */
export async function getSentinelBackupFiles(msalInstance:IPublicClientApplication, backup:String, customerId: String) {
	let token = await getUserAuthToken(msalInstance)
	
	const baseUrl = process.env.REACT_APP_BACKEND_URL!+ "/api/DownloadBackupFiles"
	const queryParams = `?backup=${backup}&cid=${customerId}`
	let backupFilesResponse = await axios.get(baseUrl + queryParams,{
		headers: { Authorization: token },
		responseType: 'blob'
	})

	return backupFilesResponse 
}  

export async function getSherlockCustomers(msalInstance:IPublicClientApplication) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/SherlockAdmin"
	let dbResponse = await axios.get(
		baseUrl,
		{ headers: { Authorization: token }}
	)

	return dbResponse
}

/**
 * 
 * @param {*} msalInstance instance of msal PCA 
 * @returns api response containing Sentinel backup files
 */
export async function getSentinelBackupList(msalInstance:IPublicClientApplication, customerId: String) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/DownloadBackupFiles"
	const queryParams = `?cid=${customerId}`

	let backupFilesResponse = await axios.get(baseUrl + queryParams,{
		headers: { Authorization: token },
		responseType: 'blob'
	})

	return backupFilesResponse 
}  

export async function getSherlockMetrics(msalInstance:IPublicClientApplication, type: "total_counts" | "weekly_metrics" | "daily_metrics" | "iocs_ranks" | "iocblacklist") {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/SherlockDashboard"
	let dbResponse = await axios.get(
		`${baseUrl}?type=${type}`,
		{ headers: { Authorization: token }}
	)

	return dbResponse
}

export async function getCustomerInfo(msalInstance:IPublicClientApplication, customerId: string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/CustomerInfo"
	const queryParams = `?cid=${customerId}`

	let customerInfoResponse = await axios.get(baseUrl + queryParams,{
		headers: { Authorization: token },
		responseType: 'json'
	})

	return customerInfoResponse 
}

export async function getHealthCheckData(msalInstance:IPublicClientApplication, customerId: string, offset=0, limit=100) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/GetHealthCheckData"
	const queryParams = `?cid=${customerId}&limit=${limit}&offset=${offset}`

	let customerInfoResponse = await axios.get(baseUrl + queryParams,{
		headers: { Authorization: token },
		responseType: 'json'
	})

	return customerInfoResponse 
}

export async function getSherlockIndicatorById(msalInstance:IPublicClientApplication, id:string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/SherlockDashboard"
	const reqObj = {
		"data_type": "indicator",
		"id": id
	}
	let dbResponse = await axios.post(
		baseUrl,
		reqObj,
		{ headers: { Authorization: token }}
	)

	return dbResponse	
}

export async function getSherlockIOCBlacklistEntryById(msalInstance:IPublicClientApplication, id:string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/SherlockDashboard"
	const reqObj = {
		"data_type": "ioc_blacklist_entry",
		"id": id
	}
	let dbResponse = await axios.post(
		baseUrl,
		reqObj,
		{ headers: { Authorization: token }}
	)

	return dbResponse	
}

export async function putSherlockIOCToBlacklist(msalInstance:IPublicClientApplication, reqObj:string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/SherlockAdmin"
	let dbResponse = await axios.put(
			baseUrl,
			reqObj,
			{ headers: { Authorization: token }}
		)

	return dbResponse
}

export async function getServices(msalInstance:IPublicClientApplication) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/Services"
	let servicesResponse = await axios.get(baseUrl, {
		headers: { Authorization: token }
	})
	
	return servicesResponse
}

export async function createCustomerInDb(msalInstance:IPublicClientApplication, reqObj:string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/CreateCustomer"
	let createCustomerResponse = await axios.post(
			baseUrl,
			reqObj,
			{ headers: { Authorization: token }}
		)

	return createCustomerResponse
}

export async function toggleCustomerEnabledInDb(msalInstance:IPublicClientApplication, reqObj:string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/EnableDisableCustomer"
	let toggleCustomerEnabledDisabledResponse = await axios.post(
			baseUrl,
			reqObj,
			{ headers: { Authorization: token }}
		)

	return toggleCustomerEnabledDisabledResponse
}

export async function addServicesToCustomer(msalInstance:IPublicClientApplication, reqObj:string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/Services"

	let addServiceResponse = await axios.post(
			baseUrl,
			reqObj,
			{ headers: { Authorization: token }}
		)

	return addServiceResponse
}

export async function getDiagnosticsData(msalInstance:IPublicClientApplication, dataType:string, offset=0, limit=100) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/Health"
	const queryParams = `?type=${dataType}&limit=${limit}&offset=${offset}`

	let healthResponse = await axios.get(baseUrl + queryParams,{
		headers: { Authorization: token },
		responseType: 'json'
	})

	return healthResponse 
}

// As we move report export to server side, this function is not used for now, keep for future functions
export async function putAuditLog(msalInstance:IPublicClientApplication, reqObj:string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/Health"

	let putResponse = await axios.put(
		baseUrl,
		reqObj,
		{ headers: { Authorization: token }}
	)

	return putResponse
}

export async function getIgnoreListForCustomer(msalInstance:IPublicClientApplication, customerId: string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/IgnoreList"
	const queryParams = `?cid=${customerId}`

	let ignoreListResponse = await axios.get(baseUrl + queryParams,{
		headers: { Authorization: token },
		responseType: 'json'
	})

	return ignoreListResponse
}

export async function addResourceToIgnoreList(msalInstance:IPublicClientApplication, customerId: string, reqObj: string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/IgnoreList"
	const queryParams = `?cid=${customerId}`

	let response = await axios.put(
		baseUrl + queryParams,
		reqObj,
		{ headers: { Authorization: token }}
	)

	return response
}

export async function removeResourceFromIgnoreList(msalInstance:IPublicClientApplication, customerId: string, resourceId: string, resourceType: string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/IgnoreList"
	const queryParams = `?cid=${customerId}&resource=${resourceId}&resourceType=${resourceType}`

	let response = await axios.delete(
		baseUrl + queryParams,
		{ headers: { Authorization: token }}
	)

	return response
}

export async function approveIgnoredResource(msalInstance:IPublicClientApplication, customerId: string, resourceId: string, resourceType: string) {
	let token = await getUserAuthToken(msalInstance)

	const baseUrl = process.env.REACT_APP_BACKEND_URL!+"/api/IgnoreList"
	const queryParams = `?cid=${customerId}&resource=${resourceId}&resourceType=${resourceType}&approve=true`

	let response = await axios.put(
		baseUrl + queryParams,
		{},
		{ headers: { Authorization: token }}
	)

	return response
}